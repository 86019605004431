<template>
  <div class="layout">
    <AppHeader />
    <main class="main">
      <router-view />
    </main>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from '@/components/AppHeader';
import AppFooter from '@/components/AppFooter';

export default {
  components: {
    AppHeader,
    AppFooter,
  },
}
</script>

<style lang="scss">
.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  background-color: #fafafa;

  .main {
    flex: 1 0 auto;
    align-self: center;

    width: 100%;
    max-width: 516px;

    padding: 20px;
  }

  .app-header,
  .app-footer {
    flex: 0 0 auto;
  }
}
</style>